import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, Img } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ThemeContext from "../../context/ThemeContext"
import Maintenance from "../../components/maintenance"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ManagedCloudServices = data => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Services of Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />
          <Maintenance />
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default ManagedCloudServices
export const query = graphql`
  query Service {
    file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
